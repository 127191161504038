@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex-shrink: 0;
    margin-bottom: _.unit(4);
  }

  .tabNav {
    margin-bottom: _.unit(4);
  }
}

.tabContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  // restrict the height of the tab content to the height of the container
  overflow: hidden;

  > * {
    flex: 1;
    margin-bottom: _.unit(6);

    // restrict the height of the tab content to the height of the container
    overflow: hidden;

    &:first-child {
      margin-right: _.unit(3);
    }
  }
}

.codePanel {
  position: relative;
  display: flex;
  flex-direction: column;

  .cardTitle {
    font: var(--font-label-2);
    margin-bottom: _.unit(3);
  }

  .flexGrow {
    flex-grow: 1;
  }
}

.hidden {
  display: none;
}
