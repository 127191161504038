const tabs = {
    get_started: '시작하기',
    dashboard: '대시보드',
    applications: '어플리케이션',
    api_resources: 'API 리소스',
    sign_in_experience: '로그인 경험',
    connectors: '연동',
    enterprise_sso: '기업 SSO',
    webhooks: '웹훅',
    organizations: '조직',
    users: '사용자 관리',
    audit_logs: '감사 기록',
    roles: '역할',
    docs: '문서',
    tenant_settings: '테넌트 설정',
    mfa: '다중 요소 인증',
    /** UNTRANSLATED */
    jwt_customizer: 'JWT Claims',
};
export default Object.freeze(tabs);
