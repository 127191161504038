@use '@/scss/underscore' as _;

.tabs {
  display: flex;
  align-items: center;
  gap: _.unit(4);
  margin-bottom: _.unit(4);


  .tab {
    display: flex;
    align-items: center;
    border-radius: 100px;
    color: var(--color-text);
    background: transparent;
    border: 1px solid var(--color-specific-selected-disabled);

    svg {
      width: 16px;
      height: 16px;
      object-fit: cover;
      color: var(--color-text-link);
    }

    &.active,
    &:hover {
      background: var(--color-inverse-primary);
      color: var(--color-white);
      border-color: var(--color-inverse-primary);

      svg {
        color: var(--color-button-icon);
      }
    }
  }
}

.tabContent {
  display: none;
  // restrict the height of the tab content to the height of the container
  overflow: hidden;

  &.active {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: _.unit(4);
  }

  .description {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }
}

.card {
  .headerRow {
    display: flex;
    flex-direction: row;
    gap: _.unit(4);
    align-items: center;
  }

  .cardHeader {
    flex: 1;
  }

  .cardTitle {
    font: var(--font-label-2);
    color: var(--color-text);
    margin-bottom: _.unit(1);
  }

  .cardSubtitle {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }

  .cardContent {
    // Collapsible content should be hidden by default, margin space can only be set at the child level
    > *:first-child {
      margin-top: _.unit(6);
    }

    > *:not(:last-child) {
      margin-bottom: _.unit(4);
    }
  }

  &.fixHeight {
    overflow: hidden;

    .cardContent {
      overflow: hidden;
    }
  }

  .expandButton {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    color: var(--color-text-secondary);
  }

  &.collapsible {
    .headerRow {
      cursor: pointer;
      user-select: none;
    }

    .cardContent {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }

    &.expanded {
      .expandButton {
        transform: rotate(180deg);
      }

      .cardContent {
        max-height: 1000;
      }
    }
  }
}

.sampleCode {
  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .monaco-editor {
      border-radius: 8px;

      /* stylelint-disable-next-line selector-class-pattern */
      .overflow-guard {
        border-radius: 8px;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .lines-content {
        padding: 0 16px;
      }
    }
  }
}

.envVariablesField {
  margin-bottom: _.unit(4);
}

/** Test Tab */

.shrinkCodeEditor {
  height: 50%;
}

.testResult {
  margin-top: _.unit(3);
  flex: 1;
  background-color: var(--color-bg-layer-2);
  border-radius: 8px;
  border: 1px solid var(--color-divider);
  font-family: 'Roboto Mono', monospace;
  overflow: auto;

  .testResultHeader {
    padding: _.unit(3) _.unit(4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: var(--font-label-2);
    font-family: 'Roboto Mono', monospace;
    color: var(--color-text);
  }

  .testResultContent {
    padding: _.unit(2) _.unit(4);
    font: var(--font-body-2);
    overflow: auto;

    pre {
      white-space: pre-wrap;

      &.error {
        color: var(--color-error);
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.error {
  margin: _.unit(4) 0;
  color: var(--color-error);
}


/** Flexbox */

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexGrow {
  flex: 1;
}
