@use '@/scss/underscore' as _;

.blockShimmer {
  @include _.shimmering-animation;
  border-radius: 8px;
  flex: 1;
}

.card:not(:last-child) {
  margin-bottom: _.unit(4);
}


.textShimmer {
  @include _.shimmering-animation;
  width: 100%;
  height: _.unit(6);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: _.unit(4);
  }

  &.title {
    width: _.unit(30);
  }

  &.large {
    height: _.unit(8);
  }
}

