const invitation = {
    find_your_tenants: 'Find your tenants',
    find_tenants_description: 'Your email address may already be registered with multiple tenants. You can choose to join the existing ones or continue create a new one.',
    create_new_tenant: 'Create a new tenant',
    email_not_match_title: 'You are currently signed in as\n{{email}}.',
    email_not_match_description: 'You do not currently have access to this organization.\nPlease sign in with the correct account to accept the invitation and become a member of the organization.',
    switch_account: 'Sign in to another account',
    invalid_invitation_status: 'Invalid invitation. Please contact the administrator and try again.',
};
export default Object.freeze(invitation);
