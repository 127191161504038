const tabs = {
    get_started: 'Empezar',
    dashboard: 'Tablero',
    applications: 'Aplicaciones',
    api_resources: 'Recursos de API',
    sign_in_experience: 'Experiencia de inicio de sesión',
    connectors: 'Conectores',
    enterprise_sso: 'SSO empresarial',
    webhooks: 'Webhooks',
    organizations: 'Organizaciones',
    users: 'Gestión de usuarios',
    audit_logs: 'Registros de auditoría',
    roles: 'Roles',
    docs: 'Documentos',
    tenant_settings: 'Configuraciones del inquilino',
    mfa: 'Autenticación multifactor',
    /** UNTRANSLATED */
    jwt_customizer: 'JWT Claims',
};
export default Object.freeze(tabs);
